import React, { ReactNode } from 'react'
import cl from './IconButton.module.css';

interface IProps {
    onClick: () => void;
    icon: string;
    size?: number
}

export const IconButton = ({icon, onClick, size = 20}: IProps) => {
  return (
    <button style={{width: size, height: size}} className={cl.button} onClick={onClick}>
        <img className={cl.icon} src={icon} alt="" />
    </button>
  )
}
